import { atom } from "jotai";
import { CountryCurrencies, Currency } from "../types/country";
import { loadable } from "jotai/utils";

const countryUrlParamAtom = atom("");

export const setCountryUrlParamAtom = atom(
  null,
  (_get, set, paramValue: string) => {
    set(countryUrlParamAtom, paramValue.toUpperCase());
  }
);

export const asyncCurrenciesAtom = atom<Promise<Currency[]>>(async (_get) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/v1/currencies/`,
    {
      method: "GET",
    }
  );
  const res = await response.json();
  return res.data;
});

export const loadableCurrenciesAtom = loadable(asyncCurrenciesAtom);

export const selectedCurrencyAtom = atom<Currency | null>((get) => {
  const countryCode = get(countryUrlParamAtom);
  if (!countryCode) {
    return null;
  }
  const currencies = get(loadableCurrenciesAtom);
  if (currencies.state === "hasError") return null;
  if (currencies.state === "loading") return null;
  const currencyOfInterest = currencies.data.find(
    (c) => c.code === CountryCurrencies[countryCode as "US" | "CA"]
  );
  return currencyOfInterest ? currencyOfInterest : null;
});

export const getCurrencySymbolAtom = atom((get) => {
  const currency = get(selectedCurrencyAtom);
  if (currency) {
    return currency.symbol;
  }
  return "";
});
