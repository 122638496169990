import { useAtom } from "jotai";
import {
  termOfServiceAcceptedAtom,
  toggleTermOfServiceAcceptedAtom,
} from "../../lib/terms-or-service";
import styles from "./TermsOfService.module.scss";
import {productAtom} from "../../lib/product";

export const TermsOfService = () => {
  const [termsAccepted] = useAtom(termOfServiceAcceptedAtom);
  const [, toggleTermsAccepted] = useAtom(toggleTermOfServiceAcceptedAtom);
  const [product] = useAtom(productAtom);

  return (
    <div className="d-flex fs-7" style={{ padding: "1em 0" }}>
      <div>
        <input
          type="checkbox"
          checked={termsAccepted}
          onClick={toggleTermsAccepted}
          readOnly={true}
        />
      </div>
      { product ? <p style={{ padding: "0 1em" }} className={styles.caption}>
        I agree that I have thoroughly read, understand, and accept the{" "}
        <a href={`${product.termsOfService}`} target="_blank" rel="noreferrer">
          Terms of Service & Conditions
        </a>. I also acknowledge and agree to
        receive and view all plan documentation, including Terms and Conditions
        and Plan Confirmation (if any), electronically as well as receive
        automated transactional messages via email and SMS in accordance with
        AKKO's Privacy Policy.
      </p> : null}
    </div>
  );
};
