import Button from "react-bootstrap/Button";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { discountAtom } from "../../lib/discount";
import { DiscountCode as DiscountCodeInterface } from "../../types/discount";
import { ReactComponent as CheckmarkDynamic } from "../../assets/checkmark-dynamic.svg";
import { ReactComponent as DiscountBackground } from "../../assets/discount-background.svg";
import { ReactComponent as DiscountBackgroundMobile } from "../../assets/discount-background-mobile.svg";
import { ReactComponent as RightArrowIconDynamic } from "../../assets/right-arrow-icon-dynamic.svg";
import styles from "./DirectDiscountBox.module.scss";

interface DirectDiscountBoxPropsInterface {
  appliedDiscount: DiscountCodeInterface | null;
  onApplyCode: (code: any) => void;
  discountCodePreDefined: string;
  title: string;
  subtitle: string;
  conditions: string[];
}

const requestAndVerifyCode = async (
  code: string,
  setIsValidCode: Dispatch<SetStateAction<boolean | null>>,
  setDiscountCode: Dispatch<SetStateAction<string>>,
  onApplyCode: (code: any) => void
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/discounts/check/${code}`,
    {
      method: "GET",
    }
  );
  if (response.status === 200) {
    setIsValidCode(true);
    const data = await response.json();
    setDiscountCode("");
    onApplyCode({
      amount: data.amount,
      amountType: data.amount_type,
      code: data.code,
      codeName: data.code_name,
      coupon: data.coupon,
      deductibleCreditValue: data.deductible_credit_value,
      discountLevel: data.discount_level,
      expirationDate: data.expiration_date,
      numberMonths: data.number_months,
      referralCode: data.referral_code,
    });
  } else {
    setIsValidCode(false);
    setDiscountCode("");
  }
};

export const DirectDiscountBox = (props: DirectDiscountBoxPropsInterface) => {
  const [discountCode, setDiscountCode] = useState("");
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [isValidCode, setIsValidCode] = useState<boolean | null>(null);
  const [discount] = useAtom(discountAtom);

  useEffect(() => {
    if (verifyingCode) {
      requestAndVerifyCode(
        discountCode,
        setIsValidCode,
        setDiscountCode,
        props.onApplyCode
      )
        .then(() => setVerifyingCode(false))
        .catch(() => setVerifyingCode(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyingCode]);

  return (
    <>
      <div className={styles.container}>
        <DiscountBackground className={styles.directDiscount} />
        <DiscountBackgroundMobile className={styles.directDiscountMobile} />
        <div className={styles.textContainerLeft}>
          <span className={styles.title}>{props.title}</span>
          <span className={styles.subtitle}>{props.subtitle}</span>
        </div>
        <div className={styles.textContainerCenterRight}>
          {props.conditions.map((condition) => (
            <span className={styles.condition}><CheckmarkDynamic className={styles.checkmark}/>{condition}</span>
          ))}
        </div>
        {discount != null && discount.code === props.discountCodePreDefined ? (
          null
        ) : (
          <div className={styles.textContainerRight}>
            <Button
              variant="secondary"
              className={`${styles.applyBtn}`}
              onClick={() => {
                setDiscountCode(props.discountCodePreDefined)
                setVerifyingCode(true)
              }}
            >
              Switch Now <RightArrowIconDynamic />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
