import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./DiscountCode.module.scss";
import { useAtom } from "jotai";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ReactComponent as ValidIcon } from "../../assets/valid-icon.svg";
import { ReactComponent as InvalidIcon } from "../../assets/invalid-icon.svg";
import { DiscountCode as DiscountCodeInterface } from "../../types/discount";
import { toggleCoverageInterval } from "../../utils/plan-interval-switch";
import { useSearchParams } from "react-router-dom";
import { selectedPriceAtom } from "../../lib/product";
import { Interval } from "../../types/interval";
import { DISCOUNT_CODE_2_YEAR } from "../../constants/promotion";
import useCookie from "../../lib/cookie";
import { remoteSetLeadAtom } from "../../lib/lead";

interface DiscountPropsCodeInterface {
  appliedDiscount: DiscountCodeInterface | null;
  onApplyCode: (code: any) => void;
}

const requestAndVerifyCode = async (
  code: string,
  setIsValidCode: Dispatch<SetStateAction<boolean | null>>,
  setDiscountCode: Dispatch<SetStateAction<string>>,
  onApplyCode: (code: any) => void
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/discounts/check/${code}`,
    {
      method: "GET",
    }
  );
  if (response.status === 200) {
    setIsValidCode(true);
    const data = await response.json();
    setDiscountCode("");
    onApplyCode({
      amount: data.amount,
      amountType: data.amount_type,
      code: data.code,
      codeName: data.code_name,
      coupon: data.coupon,
      deductibleCreditValue: data.deductible_credit_value,
      discountLevel: data.discount_level,
      expirationDate: data.expiration_date,
      numberMonths: data.number_months,
      referralCode: data.referral_code,
    });
  } else {
    setIsValidCode(false);
    setDiscountCode("");
  }
};

export const DiscountCode = (props: DiscountPropsCodeInterface) => {
  const { value: codeFromCookie } = useCookie("akko-dc");

  const [searchParams, setSearchParams] = useSearchParams();
  const [discountCode, setDiscountCode] = useState(
    searchParams.get("promo") ?? "",
  );
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [isValidCode, setIsValidCode] = useState<boolean | null>(null);
  const [price] = useAtom(selectedPriceAtom);
  const [_, remoteSetLead] = useAtom(remoteSetLeadAtom);

  useEffect(() => {
    if (discountCode !== "") {
      setVerifyingCode(true);
    }
  }, []);

  useEffect(() => {
    if (verifyingCode) {
      requestAndVerifyCode(
        discountCode,
        setIsValidCode,
        setDiscountCode,
        props.onApplyCode
      )
        .then(() => {
          // Side effect to register lead
          remoteSetLead().then((r) => null);
          setVerifyingCode(false);
          if (
            price?.interval === Interval.MONTHLY &&
            discountCode === DISCOUNT_CODE_2_YEAR
          ) {
            toggleCoverageInterval({
              e: { preventDefault: () => {} },
              searchParams: searchParams,
              setSearchParams: setSearchParams,
              internalInterval: price?.interval ? price.interval : null,
              remoteSetLead,
            });
          }
        })
        .catch(() => setVerifyingCode(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyingCode]);

  useEffect(() => {
    if (codeFromCookie) {
      setDiscountCode(codeFromCookie);
      setVerifyingCode(true);
    }
  }, [codeFromCookie]);

  return (
    <>
      <Form.Group>
        {isValidCode ? (
          <Form.Text className="d-flex align-items-center fs-6 mb-3">
            <ValidIcon className={styles.codeIcon} />
            <span>Valid code</span>
          </Form.Text>
        ) : isValidCode === false ? (
          <Form.Text className="d-flex align-items-center mb-3">
            <InvalidIcon className={styles.codeIcon} />
            <span>Invalid code</span>
          </Form.Text>
        ) : null}
        <div className="d-flex justify-content-between">
          <Form.Control
            value={discountCode}
            autoComplete="off"
            className={styles.promotionInput}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <Button
            variant="secondary"
            disabled={!discountCode || verifyingCode}
            className={`${discountCode ? "btn-action" : ""} ${styles.applyBtn}`}
            onClick={() => setVerifyingCode(true)}
          >
            Apply
          </Button>
        </div>
      </Form.Group>
    </>
  );
};
