import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { PostCheckoutSteps } from "../constants/post-checkout";
import { isUpsellMonthlyToAnnualUpsellEnabledAtom } from "../lib/feature-flags";
import {
  postCheckoutFlowAtom,
  postCheckoutFlowCurrentStepAtom,
  setPostCheckoutFlowCurrentStepAtom,
} from "../lib/post-checkout";
import { selectedPriceAtom } from "../lib/product";
import { Interval } from "../types/interval";

export function useNextPostCheckoutStep() {
  const navigate = useNavigate();
  const [postCheckoutFlow] = useAtom(postCheckoutFlowAtom);
  const [postCheckoutFlowCurrentStep] = useAtom(
    postCheckoutFlowCurrentStepAtom,
  );
  const [_, setPostCheckoutFlowCurrentStep] = useAtom(
    setPostCheckoutFlowCurrentStepAtom,
  );
  const [isUpsellMonthlyToAnnualUpsellEnabled] = useAtom(
    isUpsellMonthlyToAnnualUpsellEnabledAtom,
  );
  const [price] = useAtom(selectedPriceAtom);

  const navigateNextStep = (index: number) => {
    const nextStep = postCheckoutFlow[index];
    if (nextStep == null) {
      return;
    }
    switch (nextStep) {
      case PostCheckoutSteps.WELCOME_SCREEN:
        //todo only do this if user is web flow
        window.location.href = `${process.env.REACT_APP_CONSUMER_SITE_URL}/login`;
        break;
      case PostCheckoutSteps.COMPLETE_SCREEN:
        navigate("/post-checkout/complete");
        setPostCheckoutFlowCurrentStep(index + 1);
        break;
      case PostCheckoutSteps.MONTHLY_TO_ANNUAL:
        if (
          isUpsellMonthlyToAnnualUpsellEnabled &&
          price?.interval === Interval.MONTHLY
        ) {
          navigate("/post-checkout/mta");
          setPostCheckoutFlowCurrentStep(index + 1);
        } else {
          navigateNextStep(index + 1);
        }
        break;
      case PostCheckoutSteps.CYBER_BUNDLE:
        navigate("/post-checkout/cb");
        setPostCheckoutFlowCurrentStep(index + 1);
        break;
      default:
        navigateNextStep(index + 1);
        break;
    }
  };

  return () => navigateNextStep(postCheckoutFlowCurrentStep);
}
