import styles from "./EmailInput.module.scss";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { checkEmailFormat } from "../../utils/validations";
import { remoteSetLeadAtom } from "../../lib/lead";
import { useAtom } from "jotai";

export const EmailInput = (props: {
  className: string;
  onEmailChange: (arg0: string) => void;
}) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setEmailIsValid] = useState(false);
  const [_, remoteSetLead] = useAtom(remoteSetLeadAtom);

  useEffect(() => {
    if (isEmailValid) {
      props.onEmailChange(email);

      // Side effect to register lead
      remoteSetLead().then((r) => null);
    } else {
      props.onEmailChange("");
    }
  }, [isEmailValid, email]);

  useEffect(() => {
    setEmailIsValid(checkEmailFormat(email));
  }, [email]);

  return (
    <>
      <Form.Control
        type="email"
        placeholder="Email Address"
        autoComplete="email"
        onChange={(e) => setEmail(e.target.value)}
        style={{ marginBottom: !isEmailValid && email ? "1.75em" : "inherit" }}
        className={
          `${
            !isEmailValid && email ? styles.errorOnInput : ""
            // eslint-disable-next-line no-useless-concat
          }` + ` ${props.className}`
        }
      />
      {!isEmailValid && email ? (
        <Form.Text className={`${styles.errorCaption} fs-8`}>
          Please enter a valid email address
        </Form.Text>
      ) : null}
    </>
  );
};
