import { useSearchParams } from "react-router-dom";

import styles from "./CostSummaryAnnualToggle.module.scss";

import { toggleCoverageInterval } from "../../utils/plan-interval-switch";
import { ReactComponent as PercentageIcon } from "../../assets/percentage-icon.svg";
import { ReactComponent as CheckMarkIcon } from "../../assets/check-mark-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right-arrow-icon.svg";
import { useAtom } from "jotai/index";
import { selectedPriceAtom } from "../../lib/product";
import { remoteSetLeadAtom } from "../../lib/lead";

interface CostSummaryAnnualTogglePropsInterface {
  annualSavings: String;
}

export const CostSummaryAnnualToggle = (
  props: CostSummaryAnnualTogglePropsInterface
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [price] = useAtom(selectedPriceAtom);
  const [_, remoteSetLead] = useAtom(remoteSetLeadAtom);

  return (
    <>
      <div
        className={`container w-100 d-flex align-items-center justify-content-center ${styles.parentContainer}`}
      >
        <div className="row">
          <div className="col-1 d-flex align-items-center justify-content-center">
            <PercentageIcon className={styles.percentageIcon} />
          </div>
          <div className="col">
            <div className="row align-items-center">
              <div className={`col-5 ${styles.verticalDivider}`}>
                <div className="row g-0">
                  <div
                    className={`col-12 fs-6 cera ${styles.colorGreen} ${styles.titleSavingsAmount}`}
                  >
                    Save {props.annualSavings}!
                  </div>
                  <div
                    className={`col-12 fs-8 cera ${styles.colorGrey} ${styles.titleSavingsDescription}`}
                  >
                    By Switching to Our Annual Plan
                  </div>
                </div>
              </div>
              <div className="col d-flex align-items-center">
                <div className="row g-0">
                  <div className="col-12">
                    <div>
                      <CheckMarkIcon className={styles.checkMarkIcon} />
                      <span
                        className={`${styles.savingsBenefitsLineItemText} ${styles.colorGrey} ps-1`}
                      >
                        No cancellation fees or lock in
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div>
                      <CheckMarkIcon className={styles.checkMarkIcon} />
                      <span
                        className={`${styles.savingsBenefitsLineItemText} ${styles.colorGrey} ps-1`}
                      >
                        Receive a pro-rated refund if you cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-auto d-flex align-items-center">
            <button
              type="button"
              className={`btn btn-sm btn-block ${styles.switchToAnnualButton}`}
              onClick={(e) =>
                toggleCoverageInterval({
                  e: e,
                  searchParams: searchParams,
                  setSearchParams: setSearchParams,
                  internalInterval: price?.interval ? price.interval : null,
                  remoteSetLead,
                })
              }
            >
              <div className="d-flex align-items-center">
                <span className="pe-2">Switch to Annual</span>
                <RightArrowIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
