import { StripeCardInput } from "../stripe-card-input/StripeCardInput";
import { CustomerBasicInformation as CustomerBasicInformationInterface } from "../../types/customer";
import { CustomerBasicInformation } from "../customer-basic-information/CustomerBasicInformation";

const handleOnChangeStripeToken = (
  rawToken: any,
  onStripeTokenChange: (token: string) => void
) => {
  onStripeTokenChange(rawToken.token.id);
};

export const CheckoutCard = (props: {
  customerBasicInformation: CustomerBasicInformationInterface;
  onCustomerBasicInformationChange: (
    info: CustomerBasicInformationInterface
  ) => void;
  onStripeTokenChange: (token: string) => void;
  registrationRequestState: string;
}) => {
  return (
    <>
      <CustomerBasicInformation
        customerBasicInformation={props.customerBasicInformation}
        onInformationChange={(change) =>
          props.onCustomerBasicInformationChange(change)
        }
      />
      <div className="mt-3">
        <StripeCardInput
          relatedRequestState={props.registrationRequestState}
          onTokenCreated={(e) =>
            handleOnChangeStripeToken(e, props.onStripeTokenChange)
          }
        />
      </div>
    </>
  );
};
