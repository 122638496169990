import { atom } from "jotai";
import {
  PaymentInformation,
  PaymentMethod,
} from "../types/payment-information";

export const paymentInformationAtom = atom<PaymentInformation>({
  stripeToken: "",
  paymentMethod: PaymentMethod.CARD,
  plaidClientId: "",
  bankAccountId: "",
  plaidPublicToken: "",
});

export const setPaymentInformationAchAtom = atom(
  null,
  (
    _get,
    set,
    payload: {
      plaidClientId: string;
      bankAccountId: string;
      plaidPublicToken: string;
    }
  ) => {
    set(paymentInformationAtom, {
      stripeToken: "",
      paymentMethod: PaymentMethod.ACH,
      plaidClientId: payload.plaidClientId,
      bankAccountId: payload.bankAccountId,
      plaidPublicToken: payload.plaidPublicToken,
    });
  }
);
export const resetPaymentInformationAchAtom = atom(null, (get, set) => {
  const existingData = get(paymentInformationAtom);
  set(paymentInformationAtom, {
    stripeToken: "",
    paymentMethod: existingData.paymentMethod,
    plaidClientId: "",
    bankAccountId: "",
    plaidPublicToken: "",
  });
});
