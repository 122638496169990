import { useAtom } from "jotai";
import Button from "react-bootstrap/Button";
import {
  postCheckoutFlowAtom,
  postCheckoutFlowCurrentStepAtom,
} from "../../lib/post-checkout";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";
import styles from "./CheckoutComplete.module.scss";

export const CheckoutComplete = () => {
  const nextPostCheckoutStep = useNextPostCheckoutStep();
  const [postCheckoutFlow] = useAtom(postCheckoutFlowAtom);
  const [postCheckoutFlowCurrentStep] = useAtom(
    postCheckoutFlowCurrentStepAtom,
  );
  return (
    <div className={styles.container}>
      <h5>Success!</h5>
      <p>
        Your registration is complete. You will receive a confirmation email
        shortly with the details of your coverage.
      </p>
      {postCheckoutFlowCurrentStep < postCheckoutFlow.length && (
        <Button
          variant="primary"
          className={"btn btn-primary fs-6"}
          onClick={nextPostCheckoutStep}
        >
          Continue
        </Button>
      )}
    </div>
  );
};
