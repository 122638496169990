import { MapsSearchInputMode } from "../../constants/google-maps";
import { GoogleMapsInput } from "../google-maps-input/GoogleMapsInput";
import styles from "./AddressInformation.module.scss";
import React from "react";
import { useAtom } from "jotai";
import { customerAtom } from "../../lib/customer";

const getAddressFromGMObject = (GMObject: any): string => {
  if (GMObject && GMObject.formatted_address) {
    return GMObject.formatted_address;
  }
  return "";
};

const getPostalCodeFromGMObject = (GMObject: any): string => {
  if (
    GMObject &&
    GMObject.address_components &&
    Array.isArray(GMObject.address_components) &&
    GMObject.address_components[0] &&
    GMObject.address_components[0].short_name
  ) {
    return GMObject.address_components[0].short_name;
  }
  return "";
};

export const AddressInformation = () => {
  const [customer, setCustomer] = useAtom(customerAtom);

  return (
    <>
      <h2 className="fs-4 mb-3">Address</h2>
      <div
        className={`d-flex justify-content-between mb-3 ${styles.mFlexColumn}`}
      >
        <GoogleMapsInput
          name={"address"}
          id={"address"}
          placeHolder={"Address"}
          cssClasses={`Control ${styles.mFullWidth} ${styles.inputSpace}`}
          value={customer.address}
          inputType={MapsSearchInputMode.ADDRESS}
          onAddressChange={(GMObject) =>
            setCustomer((prev) => ({
              ...prev,
              address: getAddressFromGMObject(GMObject),
            }))
          }
          onPlainValueChange={(address) =>
            setCustomer((prev) => ({
              ...prev,
              address,
            }))
          }
        />
        <GoogleMapsInput
          name={"postal-code"}
          id={"postal-code"}
          placeHolder={"Postal Code"}
          cssClasses={`Control ${styles.mFullWidth} ${styles.inputSpace} ${styles.mt1}`}
          value={customer.postalCode}
          inputType={MapsSearchInputMode.REGION}
          onAddressChange={(GMObject) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode: getPostalCodeFromGMObject(GMObject),
            }))
          }
          onPlainValueChange={(postalCode) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode,
            }))
          }
        />
      </div>
    </>
  );
};
