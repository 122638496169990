import { atom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import { customerAtom } from "./customer";
import { productAtom, selectedPriceAtom } from "./product";

import { readDiscountAtom } from "./discount";
import {
  partnerPropertyFriendlyUrlQueryValueAtom,
  partnerPropertyUrlQueryValueAtom,
} from "./partner";
import { throttle } from "lodash";

const leadIdAtom = atom<number | null>(null);
const _leadSessionIdAtom = atom<string>(uuidv4());
const leadSessionIdAtom = atom(
  (get) => {
    return get(_leadSessionIdAtom);
  },
  (get, set, updatedLeadId: number) => {
    const currentLeadId = get(leadIdAtom);
    if (currentLeadId !== updatedLeadId) {
      set(_leadSessionIdAtom, uuidv4());
    }
  }
);

const sendUpdateLeadTransaction = throttle((leadId, leadSessionId, payload) => {
  fetch(
    `${process.env.REACT_APP_API}/leads/${leadId}/sessions/${leadSessionId}/`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  ).then((r) => null);
}, 300);

export const remoteSetLeadAtom = atom(null, async (get, set): Promise<void> => {
  const leadId = get(leadIdAtom);
  const customer = get(customerAtom);

  const selectedPrice = get(selectedPriceAtom);
  const product = get(productAtom);
  const discount = get(readDiscountAtom);
  const partner_property_id = get(partnerPropertyUrlQueryValueAtom);
  const partner_property_friendly_id = get(
    partnerPropertyFriendlyUrlQueryValueAtom
  );

  const response = await fetch(`${process.env.REACT_APP_API}/leads/`, {
    method: leadId ? "PUT" : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: customer.emailAddress,
      first_name: customer.firstName,
      last_name: customer.lastName,
      phone_no: customer.phone,
      zip: customer.postalCode,
    }),
  });
  if (response.ok) {
    const res = await response.json();
    // Lines have to be called on the following order
    set(leadSessionIdAtom, res.data.lead_id);
    set(leadIdAtom, res.data.lead_id);
  }

  const leadSessionId = get(leadSessionIdAtom);

  if (leadSessionId && leadId) {
    sendUpdateLeadTransaction(leadId, leadSessionId, {
      product_id: product?.id,
      product_price_id: selectedPrice?.id,
      partner_property_id: partner_property_id ? partner_property_id : null,
      partner_property_friendly_id: partner_property_friendly_id
        ? partner_property_friendly_id
        : null,
      discounts: discount ? [discount.code] : [],
      registration_value: selectedPrice?.currentPrice,
    });
  }
});
