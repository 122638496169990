import styles from "./AKKOLeanTemplate.module.scss";
import { ReactComponent as AKKOLogo } from "../../assets/AKKO-logo.svg";
import Button from "react-bootstrap/Button";
import { themeAtom } from "../../lib/theme";
import { useAtom } from "jotai";
import { calculateComplementary, shadeColor } from "../../utils/color";

export const AKKOLeanTemplate = (props: any) => {
  const [theme] = useAtom(themeAtom);

  return (
    <div
      className={`${styles.container} page`}
      style={
        theme.brand_name != "AKKO"
          ? {
              // @ts-ignore
              "--link-color": theme.color,
              "--border-color": theme.color,
              "--light-background": `${shadeColor(theme.color, 100)}`,
              "--btn-main-caption-color": `${calculateComplementary(
                theme.color,
                true
              )}`,
              "--main-color": theme.color,
            }
          : undefined
      }
    >
      <header className={styles.header}>
        <a href="https://getakko.com/">
          {theme.brand_name === "AKKO" ? (
            <AKKOLogo className={styles.logo} />
          ) : (
            <img
              src={theme.image_url}
              className={styles.externalLogo}
              alt={theme.brand_name}
            />
          )}
        </a>
        <div>
          <span className="fs-7" style={{ marginRight: "1em" }}>
            Need Help?
          </span>
          <Button
            variant="primary"
            style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
          >
            Contact Us
          </Button>
        </div>
      </header>
      {props.children}
    </div>
  );
};
