import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { FeatureFlag } from "../types/feature-flags";
import { MONTHLY_TO_ANNUAL_UPSELL } from "../constants/feature-flags";

export const asyncFeatureFlagsAtom = atom<Promise<FeatureFlag[]>>(
  async (_get) => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/feature_flags/`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    return res.data;
  }
);

export const loadableFeatureFlagsAtom = loadable(asyncFeatureFlagsAtom);

export const isUpsellMonthlyToAnnualUpsellEnabledAtom = atom<boolean>((get) => {
  const loadableFeatureFlags = get(loadableFeatureFlagsAtom);
  if (loadableFeatureFlags.state !== "hasData") return false;
  const targetFeatureFlag = loadableFeatureFlags.data.find(
    (ff) => ff.feature === MONTHLY_TO_ANNUAL_UPSELL
  );
  if (!targetFeatureFlag) return false;
  return targetFeatureFlag.state;
});
