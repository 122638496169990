import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { checkValidPhoneNumber } from "../../utils/validations";
import styles from "./PhoneInput.module.scss";

export interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  onChangePhoneValidity?: (value: boolean) => void;
}

const handlePhoneChange = (
  value: string,
  setIsPhoneInvalidState: (val: boolean) => void,
  onChange: (val: string) => void,
  setPhoneNumber: Dispatch<SetStateAction<string>>
) => {
  const isValid = checkValidPhoneNumber(value);
  const unmask = value.replace(/[^\d]/g, "");
  setIsPhoneInvalidState(isValid);
  setPhoneNumber(unmask);
  onChange(unmask);
};

export const PhoneInput = (props: PhoneInputProps) => {
  const [isPhoneInvalidState, setIsPhoneInvalidState] = useState(true);
  const [isPhoneDuplicated, setIsPhoneDuplicated] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (phoneNumber.length >= 10) {
      fetch(`${process.env.REACT_APP_API}/checkphone/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phoneNumber }),
      }).then((response) => {
        if (!response.ok) {
          setIsPhoneInvalidState(true);
          setIsPhoneDuplicated(true)
        } else {
          setIsPhoneDuplicated(false)
          setIsPhoneInvalidState(false);
        }
      });
    } else {
      setIsPhoneDuplicated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  useEffect(() => {
    if (props.onChangePhoneValidity) {
      props.onChangePhoneValidity(!isPhoneInvalidState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneInvalidState]);

  return (
    <Form.Group className={styles.mFullWidth}>
      <InputMask
        mask="(999) 999-9999"
        value={props.value}
        onChange={(e) =>
          handlePhoneChange(
            e.target.value,
            setIsPhoneInvalidState,
            props.onChange,
            setPhoneNumber
          )
        }
      >
        <Form.Control
          type="tel"
          placeholder="Cell Phone Number"
          autoComplete="tel"
          className={
            `${
              isPhoneInvalidState && phoneNumber
                ? styles.errorOnInput
                : ""
              // eslint-disable-next-line no-useless-concat
            }` + ` ${props.className}`
          }
        />
      </InputMask>
      {isPhoneInvalidState && phoneNumber ? (
        <Form.Text className={`${styles.errorCaption} fs-8`}>
          { isPhoneDuplicated ? 'This phone number is already used by another account' : 'Please enter a valid phone number'}
        </Form.Text>
      ) : null}
    </Form.Group>
  );
};
