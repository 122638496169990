import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentForm } from "./payment-form/PaymentForm";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
export const StripeCardInput = (props: {
  onTokenCreated: (token: any) => void;
  relatedRequestState: string;
}) => {
  return (
    <Elements stripe={promise}>
      <PaymentForm
        onTokenCreated={(e) => props.onTokenCreated(e)}
        relatedRequestState={props.relatedRequestState}
      />
    </Elements>
  );
};
