import { atom } from "jotai";
import {
	DEFAULT_POST_CHECKOUT_FLOW,
  MonthlyToAnnualVariation,
  PostCheckoutSteps,
} from "../constants/post-checkout";

const postCheckoutPreferredNextStepAtom = atom<PostCheckoutSteps>(
  PostCheckoutSteps.MONTHLY_TO_ANNUAL
);

export const postCheckoutFlowAtom = atom<PostCheckoutSteps[]>(DEFAULT_POST_CHECKOUT_FLOW);

export const setPostCheckoutFlowAtom = atom(
  null,
  (_get, set, flow: PostCheckoutSteps[]) => {
    set(postCheckoutFlowAtom, flow);
  },
);

export const postCheckoutFlowCurrentStepAtom = atom<number>(0);

export const setPostCheckoutFlowCurrentStepAtom = atom(
  null,
  (_get, set, step: number) => {
    set(postCheckoutFlowCurrentStepAtom, step);
  },
);

export const monthlyToAnnualVariationAtom = atom<MonthlyToAnnualVariation>(
  MonthlyToAnnualVariation.PERCENTAGE_AMOUNT
);

export const setPostCheckoutStepQueryValueAtom = atom(
  null,
  (_get, set, queryValue: Array<string>) => {
    //* uses default flow if length is
    if (queryValue.length > 0) {
      set(
        postCheckoutFlowAtom,
        queryValue
          .map((v) => {
            switch (v) {
              case "cb":
                return PostCheckoutSteps.CYBER_BUNDLE;
              case "mta":
                return PostCheckoutSteps.MONTHLY_TO_ANNUAL;
              case "wlc":
                return PostCheckoutSteps.WELCOME_SCREEN;
              case "compl":
                return PostCheckoutSteps.COMPLETE_SCREEN;
              default:
                return null;
            }
          })
          .filter((v) => v !== null) as PostCheckoutSteps[],
      );
    }
  }
);

export const setMonthlyToAnnualVariationQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    if (queryValue === "pv") {
      set(
        monthlyToAnnualVariationAtom,
        MonthlyToAnnualVariation.PERCENTAGE_AMOUNT,
      );
    }
    if (queryValue === "mv") {
      set(monthlyToAnnualVariationAtom, MonthlyToAnnualVariation.PLAIN_AMOUNT);
    }
  }
);
