import Button from "react-bootstrap/Button";
import { ReactComponent as AdmirationMark } from "../../assets/admiration-mark.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as TopArrow } from "../../assets/top-arrow.svg";
import { PaymentMethod } from "../../constants/payment";
import styles from "./PaymentMethodSwitch.module.scss";

const handleOnMethodUpdate = (
  oldMethod: PaymentMethod,
  newMethod: PaymentMethod,
  updateMethod: (arg0: PaymentMethod) => void
) => {
  if (oldMethod !== newMethod) {
    updateMethod(newMethod);
  }
};

export const PaymentMethodSwitch = (props: {
  method: PaymentMethod;
  onMethodUpdate: (method: PaymentMethod) => void;
}) => {
  return (
    <>
      <div className="position-relative">
        <Button
          onClick={() =>
            handleOnMethodUpdate(
              props.method,
              PaymentMethod.ACH,
              props.onMethodUpdate
            )
          }
          variant={props.method === PaymentMethod.ACH ? "primary" : "secondary"}
          className={`${styles.btnSwitch} btn-toggle ${styles.left}`}
          style={{ position: "relative" }}
        >
          Bank Account
        </Button>
        <Button
          onClick={() =>
            handleOnMethodUpdate(
              props.method,
              PaymentMethod.CARD,
              props.onMethodUpdate
            )
          }
          variant={
            props.method === PaymentMethod.CARD ? "primary" : "secondary"
          }
          className={`${styles.btnSwitch} btn-toggle ${styles.right}`}
          style={{ marginLeft: "-4px", position: "relative" }}
        >
          Credit/Debit Card
        </Button>
      </div>
      {props.method === PaymentMethod.CARD ? (
        <div className="d-flex flex-column mt-2">
          <TopArrow className={styles.arrowStyling} />
          <div
            className={`d-flex w-100 justify-content-between align-items-center fs-7 ${styles.bankBanner}`}
          >
            <div className="d-flex align-items-center">
              <AdmirationMark />
              <p className="mb-0" style={{ marginLeft: "1rem" }}>
                Change to bank account and get $20 in deductible credit for
                future claims!
              </p>
            </div>
            <CloseIcon />
          </div>
        </div>
      ) : null}
    </>
  );
};
