import { Modal } from "../modal/Modal";
import { useAtom } from "jotai";
import { loadableCurrenciesAtom } from "../../lib/country";
import { loadableRetrieveProductsAtom } from "../../lib/product";
import { useEffect, useState } from "react";
import { loadableRequestResponseAtom } from "../../lib/registration";
import { loadableRequestResponseToUpdateCoverageAtom } from "../../lib/update-coverage";
import {
  loadableOptInWalnutUpsellResponseAtom,
  loadableWalnutProductPriceAtom,
  loadableWalnutProductRequestAtom,
} from "../../lib/product-upsell";
import { loadableGetUserContractAtom } from "../../lib/contract";
import { loadableFeatureFlagsAtom } from "../../lib/feature-flags";

export const LoadingModal = () => {
  const [loadableCurrencies] = useAtom(loadableCurrenciesAtom);
  const [loadableProductsCoverageCombo] = useAtom(loadableRetrieveProductsAtom);
  const [loadableRegistrationRequestResponse] = useAtom(
    loadableRequestResponseAtom
  );
  const [loadableRequestResponseToUpdateCoverage] = useAtom(
    loadableRequestResponseToUpdateCoverageAtom
  );
  const [appLoading, setAppLoading] = useState(false);
  const [loadableWalnutProductRequest] = useAtom(
    loadableWalnutProductRequestAtom
  );
  const [loadableWalnutProductPrice] = useAtom(loadableWalnutProductPriceAtom);
  const [loadableGetUserContract] = useAtom(loadableGetUserContractAtom);
  const [loadableOptInWalnutUpsellResponse] = useAtom(
    loadableOptInWalnutUpsellResponseAtom
  );
  const [loadableFeatureFlags] = useAtom(loadableFeatureFlagsAtom);

  useEffect(() => {
    if (
      loadableCurrencies.state === "loading" ||
      loadableProductsCoverageCombo.state === "loading" ||
      loadableRegistrationRequestResponse.state === "loading" ||
      loadableRequestResponseToUpdateCoverage.state === "loading" ||
      loadableWalnutProductRequest.state === "loading" ||
      loadableWalnutProductPrice.state === "loading" ||
      loadableOptInWalnutUpsellResponse.state === "loading" ||
      loadableGetUserContract.state === "loading" ||
      loadableFeatureFlags.state === "loading"
    ) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [
    loadableCurrencies,
    loadableProductsCoverageCombo,
    loadableRegistrationRequestResponse,
    loadableRequestResponseToUpdateCoverage,
    loadableWalnutProductRequest,
    loadableWalnutProductPrice,
    loadableOptInWalnutUpsellResponse,
    loadableGetUserContract,
    loadableFeatureFlags,
  ]);

  return (
    <Modal
      headingCaption="Loading"
      modalProperties={{ show: appLoading }}
      size="sm"
    >
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "1em", paddingLeft: "1em" }}
      >
        <div className="d-flex justify-content-center flex-column align-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{ color: "var(--border-color)" }}
          />
          <p className="mt-4" style={{ marginLeft: "-1em" }}>
            Loading...
          </p>
        </div>
      </div>
    </Modal>
  );
};
