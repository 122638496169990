import { atom } from "jotai";
import { Interval } from "../types/interval";

const intervalUrlQueryValueAtom = atom<string>("");

export const setIntervalUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(intervalUrlQueryValueAtom, queryValue);
  }
);

export const intervalAtom = atom<Interval>((get) => {
  const intervalUrlQueryValue = get(intervalUrlQueryValueAtom);
  if (intervalUrlQueryValue) {
    return intervalUrlQueryValue as Interval;
  }
  return Interval.ANNUAL;
});
