import Form from "react-bootstrap/Form";
import { AchConnectionButton } from "../ach-connection-button/AchConnectionButton";
import styles from "./AchBankAccountInformation.module.scss";
import { useAtom } from "jotai";
import {customerAtom, customerEmailAtom} from "../../../lib/customer";
import { EmailInput } from "../../email-input/EmailInput";
import {MapsSearchInputMode} from "../../../constants/google-maps";
import {GoogleMapsInput} from "../../google-maps-input/GoogleMapsInput";
import React from "react";

const getPostalCodeFromGMObject = (GMObject: any): string => {
  if (
    GMObject &&
    GMObject.address_components &&
    Array.isArray(GMObject.address_components) &&
    GMObject.address_components[0] &&
    GMObject.address_components[0].short_name
  ) {
    return GMObject.address_components[0].short_name;
  }
  return "";
};

export const AchBankAccountInformation = (props: {
  institution: string;
  accountType: string;
  accountMask: string;
  accountName: string;
  message: string;
  linkToken: string | null;
  onUpdateAchPaymentData: (data: any) => void;
}) => {
  const [, setEmail] = useAtom(customerEmailAtom);
  const [customer, setCustomer] = useAtom(customerAtom);

  return (
    <>
      <Form.Group className={`mb-5 w-50 ${styles.mFullWidth}`}>
        <EmailInput
          className="Control mb-3"
          onEmailChange={(email) => setEmail(email)}
        />
        <GoogleMapsInput
          name={"postal-code"}
          id={"postal-code"}
          placeHolder={"Postal Code"}
          cssClasses={`Control`}
          value={customer.postalCode}
          inputType={MapsSearchInputMode.REGION}
          onAddressChange={(GMObject) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode: getPostalCodeFromGMObject(GMObject),
            }))
          }
          onPlainValueChange={(postalCode) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode,
            }))
          }
        />
      </Form.Group>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span
            className={`${styles.bankInformationCaption} ${styles.captionTittle}`}
          >
            SELECTED ACCOUNT
          </span>
          <span className={`${styles.bankInformationCaption} fs-7`}>
            {`${props.institution} - ${props.accountType} - ${props.accountName} (${props.accountMask})`}
          </span>
        </div>
        <AchConnectionButton
          linkToken={props.linkToken}
          onUpdateAchPaymentData={props.onUpdateAchPaymentData}
          buttonClass="none"
          caption="Update Bank Details"
        />
      </div>
      {props.message ? (
        <p className={`fs-7 mt-3 ${styles.errorMessage}`}>{props.message}</p>
      ) : null}
    </>
  );
};
