import { atom } from "jotai";
import { User } from "../types/user";

export const userAtom = atom<User | null>(null);

export const setUserAtom = atom(null, (_get, set, userPayload: User) => {
  set(userAtom, userPayload);
});

export const getUserAtom = atom((get) => {
  return get(userAtom);
});
