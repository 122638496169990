import { loadable } from "jotai/utils";
import { atom } from "jotai/index";
import { Theme } from "../types/theme";

export const asyncThemeAtom = atom<Promise<Theme[]>>(async (_get) => {
  const domain = window.location.hostname;
  const response = await fetch(
    `${process.env.REACT_APP_API}/api/v1/configurations/themes/?domain=${domain}`,
    {
      method: "GET",
    }
  );
  return await response.json();
});

export const loadableThemeAtom = loadable(asyncThemeAtom);

export const themeAtom = atom<Theme>((get) => {
  const defaultTheme = {
    active: true,
    brand_name: `${process.env.REACT_APP_WHITE_LABEL_BRAND_NAME}`,
    color: `${process.env.REACT_APP_WHITE_LABEL_COLOR}`,
    date_created: "",
    date_deleted: "",
    date_updated: "",
    domain: "",
    id: "",
    image_url: `${process.env.REACT_APP_WHITE_LABEL_BRAND_IMAGE_URL}`,
    partner_transient_id: "",
    tab_icon_url: `${process.env.REACT_APP_WHITE_LABEL_FAVICON_LINK}`,
  };

  return defaultTheme;
});
