import styles from "./DynamicCheckout.module.scss";
import { CheckoutSummary } from "../../components/checkout-summary/CheckoutSummary";
import { PaymentInformation } from "../../components/payment-information/PaymentInformation";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PromotionSection } from "../../components/promotion-section/PromotionSection";
import { DirectDiscountSection } from "../../components/direct-discount-section/DirectDiscountSection";
import { CostSummary } from "../../components/cost-summary/CostSummary";
import { useAtom } from "jotai";
import { setProductUrlIdentifierAtom } from "../../lib/product";
import { setIntervalUrlQueryValueAtom } from "../../lib/interval";
import { setPriceGroupUrlQueryValueAtom } from "../../lib/price_group";
import { TermsOfService } from "../../components/terms-of-service/TermsOfService";
import { RegisterCallToAction } from "../../components/register-call-to-action/RegisterCallToAction";
import { setCountryUrlParamAtom } from "../../lib/country";
import { LoadingModal } from "../../components/loading-modal/LoadingModal";
import {
  setPartnerPropertyUrlQueryValueAtom,
  setSalesRepUrlQueryValueAtom,
  setPartnerPropertyFriendlyUrlQueryValueAtom,
  setSalesRepFriendlyUrlQueryValueAtom,
} from "../../lib/partner";
import { RegistrationErrorModal } from "../../components/registration-error-modal/RegistrationErrorModal";
import {
  setMonthlyToAnnualVariationQueryValueAtom,
  setPostCheckoutStepQueryValueAtom,
} from "../../lib/post-checkout";
import { setPlanIntervalSwitchQueryValueAtom } from "../../lib/plan-interval-switch";
import { AddressInformation } from "../../components/address-information/AddressInformation";
import { FullPageSpinner } from "../../components/full-page-spinner/FullPageSpinner";
import { loadableThemeAtom } from "../../lib/theme";
import useCookie from "../../lib/cookie";
import { setAvailablePaymentMethodQueryValueAtom } from "../../lib/available-payment-methods";

export const DynamicCheckout = () => {
  const { value: ppFromCookie } = useCookie("akko-pp");

  const [showAddress] = useState(false);

  let { countryCode, productUrl } = useParams();

  const [searchParams] = useSearchParams();
  const interval = searchParams.get("interval");
  const partnerProperty = searchParams.get("pp");
  const partnerPropertyFriendly = searchParams.get("ppf");
  const salesRep = searchParams.get("sr");
  const salesRepFriendly = searchParams.get("srf");
  const preferredPostCheckoutStep = searchParams.getAll("ppc");
  const availablePaymentMethod = searchParams.get("apm");
  const monthlyToAnnualPostCheckoutVariation = searchParams.get("mtav");
  const planIntervalSwitchType = searchParams.get("sta"); // switch to annual
  const priceGroup = searchParams.get("price_group");
  const hidePromotions = searchParams.get("hp") === "true";
  const hidePromotions2YearUpfront = searchParams.get("hp2y") === "true";

  const [, setIntervalUrlQueryValue] = useAtom(setIntervalUrlQueryValueAtom);
  const [, setCountryUrlParam] = useAtom(setCountryUrlParamAtom);
  const [, setPartnerPropertyUrlQueryValue] = useAtom(
    setPartnerPropertyUrlQueryValueAtom
  );
  const [, setPartnerPropertyFriendlyUrlQueryValue] = useAtom(
    setPartnerPropertyFriendlyUrlQueryValueAtom
  );
  const [, setSalesRepUrlQueryValue] = useAtom(setSalesRepUrlQueryValueAtom);
  const [, setSalesRepFriendlyUrlQueryValue] = useAtom(
    setSalesRepFriendlyUrlQueryValueAtom
  );
  const [, setPostCheckoutStepQueryValue] = useAtom(
    setPostCheckoutStepQueryValueAtom
  );
  const [, setMonthlyToAnnualVariationQueryValue] = useAtom(
    setMonthlyToAnnualVariationQueryValueAtom
  );
  const [, setPlanIntervalSwitch] = useAtom(
    setPlanIntervalSwitchQueryValueAtom
  );
  const [, setPriceGroupUrlQueryValue] = useAtom(
    setPriceGroupUrlQueryValueAtom
  );
  const [, setAvailablePaymentMethod] = useAtom(
    setAvailablePaymentMethodQueryValueAtom,
  );
  const [loadableTheme] = useAtom(loadableThemeAtom);

  useEffect(() => {
    if (interval) {
      setIntervalUrlQueryValue(interval);
    }
    if (partnerProperty || ppFromCookie) {
      setPartnerPropertyUrlQueryValue(partnerProperty || ppFromCookie);
    }
    if (partnerPropertyFriendly) {
      setPartnerPropertyFriendlyUrlQueryValue(partnerPropertyFriendly);
    }
    if (salesRep) {
      setSalesRepUrlQueryValue(salesRep);
    }
    if (salesRepFriendly) {
      setSalesRepFriendlyUrlQueryValue(salesRepFriendly);
    }

    if (preferredPostCheckoutStep) {
      setPostCheckoutStepQueryValue(preferredPostCheckoutStep);
    }
    if (monthlyToAnnualPostCheckoutVariation) {
      setMonthlyToAnnualVariationQueryValue(
        monthlyToAnnualPostCheckoutVariation
      );
    }
    if (planIntervalSwitchType) {
      setPlanIntervalSwitch(planIntervalSwitchType);
    }
    if (priceGroup) {
      setPriceGroupUrlQueryValue(priceGroup);
    }
    if (availablePaymentMethod) {
      setAvailablePaymentMethod(availablePaymentMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    interval,
    partnerProperty,
    ppFromCookie,
    salesRep,
    preferredPostCheckoutStep,
    monthlyToAnnualPostCheckoutVariation,
    planIntervalSwitchType,
    priceGroup,
    availablePaymentMethod,
  ]);

  const [, setProductUrlIdentifier] = useAtom(setProductUrlIdentifierAtom);

  useEffect(() => {
    if (productUrl) {
      setProductUrlIdentifier(productUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUrl]);

  useEffect(() => {
    if (countryCode) {
      setCountryUrlParam(countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <main className={styles.container}>
      <div className={`${styles.subContainer} ${styles.mmt1}`}>
        <section>
          <CheckoutSummary />
        </section>
      </div>
      <div className={`${styles.subContainer} ${styles.mmt2}`}>
        <section className="mt-4">
          <PaymentInformation />
        </section>
      </div>
      {
        // Address component is disabled until change of request
        showAddress ? (
          <div className={`${styles.subContainer} ${styles.mmt2}`}>
            <section className="mt-4">
              <AddressInformation />
            </section>
          </div>
        ) : null
      }

      <div className={`${styles.subContainer} ${styles.mmt2} ${styles.mmb1}`}>
        <section className="mt-4">
          {hidePromotions ? null : <PromotionSection />}
        </section>
        <section className="mt-5">
          <CostSummary />
        </section>
        <section className="mt-4">
          {hidePromotions2YearUpfront ? null : <DirectDiscountSection />}
        </section>
        <section>
          <TermsOfService />
        </section>
        <section>
          <RegisterCallToAction />
        </section>
      </div>
      <LoadingModal />
      <RegistrationErrorModal />
      {loadableTheme.state == "loading" ? <FullPageSpinner /> : null}
    </main>
  );
};
