import { Interval } from "../types/interval";

export const formatNumber = function (amount: number): string {
  return Math.abs(amount).toFixed(2).replace(/\.00$/, "");
};

export const toggleCoverageInterval = ({
  e,
  searchParams,
  setSearchParams,
  internalInterval,
  remoteSetLead,
}: {
  e: any;
  searchParams: URLSearchParams;
  setSearchParams: (arg0: any) => void;
  internalInterval: string | null;
  remoteSetLead: (arg0: any) => void;
}) => {
  e.preventDefault();

  let interval = searchParams.get("interval");
  interval = interval ? interval : internalInterval;
  const params: any = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  if (interval === Interval.MONTHLY) {
    setSearchParams({
      ...params,
      ppc: searchParams.getAll("ppc"),
      interval: Interval.ANNUAL,
    });
  }

  if (interval === Interval.ANNUAL) {
    setSearchParams({
      ...params,
      ppc: searchParams.getAll("ppc"),
      interval: Interval.MONTHLY,
    });
  }

  setTimeout(remoteSetLead, 100);
};
