import UpsellToAnnualImage from "../../assets/UpsellToAnnual.svg";
import { ReactComponent as PercentageIcon } from "../../assets/percentage-icon.svg";
import { ReactComponent as CheckMark } from "../../assets/checkmark.svg";
import styles from "./UpsellToAnnual.module.scss";
import Button from "react-bootstrap/Button";
import {
  counterpartPriceAtom,
  savingOnChangePriceAtom,
  savingPercentageOnChangePriceAtom,
} from "../../lib/product";
import { useAtom } from "jotai";
import {
  monthlyToAnnualVariationAtom,
} from "../../lib/post-checkout";
import {
  MonthlyToAnnualVariation,
} from "../../constants/post-checkout";
import { getCurrencySymbolAtom } from "../../lib/country";
import { updateToPrice } from "../../lib/update-coverage";
import { SuccessfulMonthlyToAnnualUpsellModal } from "../../components/successful-monthly-to-annual-upsell-modal/SuccessfulMonthlyToAnnualUpsellModal";
import { LoadingModal } from "../../components/loading-modal/LoadingModal";
import { RegistrationErrorModal } from "../../components/registration-error-modal/RegistrationErrorModal";
import React from "react";
import { UpsellMonthlyToAnnualError } from "../../components/upsell-monthly-to-annual-error/UpsellMonthlyToAnnualError";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";

export const UpsellToAnnual = () => {
  const [savingOnChangeCoverage] = useAtom(savingOnChangePriceAtom);
  const [savingPercentageOnChangeCoverage] = useAtom(
    savingPercentageOnChangePriceAtom
  );
  const [monthlyToAnnualVariation] = useAtom(monthlyToAnnualVariationAtom);
  const [getCurrencySymbol] = useAtom(getCurrencySymbolAtom);
  const [, updateToCoverage] = useAtom(updateToPrice);
  const [counterpartPrice] = useAtom(counterpartPriceAtom);
  const nextPostCheckoutStep = useNextPostCheckoutStep();

  return (
    <>
      <div className={styles.outsideContainer}>
        <main className={`${styles.container} d-flex`}>
          <div
            className={styles.illustrationContainer}
            style={{
              backgroundImage: `url(${UpsellToAnnualImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className={`${styles.promotionDetailsContainer} position-relative`}
          >
            <div className={styles.promotionTittleSection}>
              <div className={styles.tittleIcon}>
                <PercentageIcon />
              </div>
              <div className={styles.tittleCaption}>
                <h4 className="mt-3">Upgrade to Our Annual Plan</h4>
                <p className="mb-4">
                  <span className={`${styles.promotionAmountCaption} cera`}>
                    {monthlyToAnnualVariation ===
                    MonthlyToAnnualVariation.PLAIN_AMOUNT
                      ? `Pay ${getCurrencySymbol}${savingOnChangeCoverage.toFixed(
                          2
                        )} less`
                      : `Save ${(
                          savingPercentageOnChangeCoverage * 100
                        ).toFixed(0)}%`}
                  </span>
                  <span> + Receive $12 deductible credits</span>
                </p>
              </div>
            </div>
            <p className={`${styles.promotionParagraph} mb-3`}>
              Thank you for choosing AKKO. You’ve just unlocked a special price
              for our annual plan! If you upgrade to an annual plan now, you’ll{" "}
              <b>
                save{" "}
                {monthlyToAnnualVariation ===
                MonthlyToAnnualVariation.PLAIN_AMOUNT
                  ? `${getCurrencySymbol}${savingOnChangeCoverage.toFixed(2)}`
                  : `${(savingPercentageOnChangeCoverage * 100).toFixed(0)}%`}
              </b>{" "}
              while also <b>receiving $12 deductible credits</b> for future
              claims!
            </p>
            <ul className={styles.benefitList}>
              <li className="mb-2">
                <CheckMark />
                <span className={styles.itemCaption}>
                  {" "}
                  Instant upgrade process
                </span>
              </li>
              <li className="mb-2">
                <CheckMark />
                <span className={styles.itemCaption}>
                  {" "}
                  Cancel anytime with no fees and receive pro-rated refund
                </span>
              </li>
              <li>
                <CheckMark />
                <span className={styles.itemCaption}>
                  {" "}
                  full refund if cancelled within 30 days
                </span>
              </li>
            </ul>
            <div className={`${styles.callToActionContainer}`}>
              <Button
                variant="secondary"
                className={`${styles.secondary} caption ${styles.showLargeScreen}`}
                onClick={nextPostCheckoutStep}
              >
                Skip for Now
              </Button>
              <Button
                variant="primary"
                className={`${styles.secondary} ${styles.showResponsive}`}
                onClick={nextPostCheckoutStep}
              >
                Skip for Now
              </Button>
              <Button
                variant="primary"
                className={`${styles.callToActionBtn} filled`}
                onClick={() => {
                  updateToCoverage(counterpartPrice?.id);
                }}
              >
                Upgrade Now
              </Button>
            </div>
          </div>
        </main>
      </div>
      <LoadingModal />
      <RegistrationErrorModal />
      <SuccessfulMonthlyToAnnualUpsellModal />
      <UpsellMonthlyToAnnualError />
    </>
  );
};
