export const shadeColor = function (color: string, percent: number) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  let RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const calculateComplementary = function (color: string, hex = false) {
  if (hex) {
    return (
      "#" +
      ("0" + (255 - parseInt(color.substring(1, 3), 16)).toString(16)).slice(
        -2
      ) +
      ("0" + (255 - parseInt(color.substring(3, 5), 16)).toString(16)).slice(
        -2
      ) +
      ("0" + (255 - parseInt(color.substring(5, 7), 16)).toString(16)).slice(-2)
    );
  } else {
    // @ts-ignore
    return [255 - color[0], 255 - color[1], 255 - color[2]];
  }
};
