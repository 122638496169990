import { Modal } from "../modal/Modal";
import SuccessfulUpsell from "../../assets/successful-upsell.svg";
import styles from "./SuccessfulMonthlyToAnnualUpsellModal.module.scss";
import Button from "react-bootstrap/Button";
import { updateCoverageRequestResponseAtom } from "../../lib/update-coverage";
import { useAtom } from "jotai";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";

export const SuccessfulMonthlyToAnnualUpsellModal = () => {
  const nextPostCheckoutStep = useNextPostCheckoutStep();
  const [updateCoverageRequestResponse] = useAtom(
    updateCoverageRequestResponseAtom
  );

  return (
    <Modal
      headingCaption={""}
      size="lg"
      modalProperties={{
        show: updateCoverageRequestResponse && updateCoverageRequestResponse.ok,
        dialogClassName: styles.modalOverride,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${SuccessfulUpsell})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className={styles.illustrationContainer}
      ></div>
      <div className={styles.contentContainer}>
        <h1 className="fs-6">Successfully Upgraded to Annual Plan!</h1>
        <p className={`${styles.caption} fs-8`}>
          Congrats! Your monthly plan has been successfully upgraded to an
          annual basis
        </p>
        <Button
          variant="primary"
          className={`filled ${styles.callToActionBtn}`}
          onClick={nextPostCheckoutStep}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};
