import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

function useCookie(cookieName: string) {
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  const getCookieValue = () => cookies[cookieName] || null;

  const setCookieValue = (value: any, options?: CookieSetOptions) => {
    setCookie(cookieName, value, options);
  };

  const removeCookieValue = () => {
    removeCookie(cookieName);
  };

  return {
    value: getCookieValue(),
    set: setCookieValue,
    remove: removeCookieValue,
  };
}

export default useCookie;
