import { Modal } from "../modal/Modal";
import { useAtom } from "jotai";
import {
  loadableRequestResponseTextAtom,
  requestResponseAtom,
  resetRegistrationRequestResponseAtom,
} from "../../lib/registration";
import { resetPaymentInformationAchAtom } from "../../lib/payment-information";

export const RegistrationErrorModal = () => {
  const [requestResponse] = useAtom(requestResponseAtom);
  const [loadableRequestResponseText] = useAtom(
    loadableRequestResponseTextAtom
  );
  const [, resetPaymentInformationAch] = useAtom(
    resetPaymentInformationAchAtom
  );
  const [, resetRegistrationRequestResponse] = useAtom(
    resetRegistrationRequestResponseAtom
  );

  const handleAcceptError = () => {
    resetPaymentInformationAch();
    resetRegistrationRequestResponse();
  };

  return (
    <Modal
      headingCaption="An error ocurrered during the registration"
      modalProperties={{ show: requestResponse ? !requestResponse.ok : false }}
      closeButtonCaption="Accept"
      size="md"
      onHide={handleAcceptError}
    >
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "1em", paddingLeft: "1em" }}
      >
        <p>
          {loadableRequestResponseText.state === "hasData"
            ? loadableRequestResponseText.data
            : ""}
        </p>
      </div>
    </Modal>
  );
};
