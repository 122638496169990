export const NotFound = () => {
  return (
    <p className="text-center mt-5">
      Sorry this page doesnt exists yet.{" "}
      <a style={{ cursor: "pointer" }} href="https://getakko.com/">
        Go to our home page
      </a>
    </p>
  );
};
