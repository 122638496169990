import styles from "./AKKOTemplate.module.scss";
import { ReactComponent as AKKOLogo } from "../../assets/AKKO-logo.svg";
import { useAtom } from "jotai/index";
import { themeAtom } from "../../lib/theme";
import { calculateComplementary, shadeColor } from "../../utils/color";

export const AKKOTemplate = (props: any) => {
  const [theme] = useAtom(themeAtom);

  return (
    <div
      className={`${styles.container} page`}
      style={
        theme.brand_name != "AKKO"
          ? {
              // @ts-ignore
              "--link-color": theme.color,
              "--border-color": theme.color,
              "--light-background": `${shadeColor(theme.color, 100)}`,
              "--btn-main-caption-color": `${shadeColor(theme.color, -100)}`,
              "--main-color": theme.color,
            }
          : undefined
      }
    >
      <div
        className={styles.circle}
        style={
          theme.brand_name != "AKKO"
            ? { backgroundColor: `${theme.color}30` }
            : undefined
        }
      />
      <header className={styles.header}>
        <a href="https://getakko.com/">
          {theme.brand_name === "AKKO" ? (
            <AKKOLogo className={styles.logo} />
          ) : (
            <img
              src={theme.image_url}
              className={styles.externalLogo}
              alt={theme.brand_name}
            />
          )}
        </a>
      </header>
      {props.children}
    </div>
  );
};
