import { atom, Getter } from "jotai";
import { ProductPrice } from "../types/product";
import { WALNUT } from "../constants/upsell-products";
import { userAtom } from "./user";
import { loadable } from "jotai/utils";
import { intervalAtom } from "./interval";
import { selectedCurrencyAtom } from "./country";
import { loadableGetUserContractAtom } from "./contract";
import { Contract } from "../types/contract";
import { Interval } from "../types/interval";

export const asyncWalnutProductAtom = atom(async (_get) => {
  return fetch(`${process.env.REACT_APP_API}/products/?name=${WALNUT}`, {
    method: "GET",
  }).then((r) => r.json());
});

export const loadableWalnutProductRequestAtom = loadable(
  asyncWalnutProductAtom
);

export const asyncWalnutProductPriceAtom = atom(
  async (get): Promise<ProductPrice | null> => {
    const interval = get(intervalAtom);
    const selectedCurrency = get(selectedCurrencyAtom);
    const loadableWalnutProductRequest = get(loadableWalnutProductRequestAtom);
    if (loadableWalnutProductRequest.state !== "hasData")
      return new Promise(() => null);

    let walnutUpsell = loadableWalnutProductRequest.data[0];
    const url = new URL(
      `${process.env.REACT_APP_API}/products/${walnutUpsell.id}/prices/`
    );
    url.search = new URLSearchParams({
      is_default: "true",
      interval: interval,
      currency: selectedCurrency?.code || "USD",
    }).toString();
    // @ts-ignore
    return fetch(url, {
      method: "GET",
    }).then(async (r) => {
      const response = await r.json();
      return response[0];
    });
  }
);

export const loadableWalnutProductPriceAtom = loadable(
  asyncWalnutProductPriceAtom
);

export const asyncOptInWalnutUpsellResponseAtom = atom<any | null>(null);

export const optInWalnutUpsellAtom = atom(null, async (get, set) => {
  const user = get(userAtom);
  const loadableGetUserContract = get(loadableGetUserContractAtom);
  const loadableWalnutProductPrice = get(loadableWalnutProductPriceAtom);
  if (
    !user ||
    loadableGetUserContract.state !== "hasData" ||
    loadableWalnutProductPrice.state !== "hasData"
  )
    return new Promise(() => null);
  const contract = loadableGetUserContract.data;
  const price = loadableWalnutProductPrice.data;
  const response = fetch(
    `${process.env.REACT_APP_API}/users/${user.id}/contracts/${
      (contract as Contract).id
    }/contract_line_items/`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user.id,
        contract_id: (contract as Contract).id,
        product_price_id: (price as ProductPrice).id,
        on_contract: true,
      }),
    }
  );
  set(asyncOptInWalnutUpsellResponseAtom, response);
});

export const loadableOptInWalnutUpsellResponseAtom = loadable(
  asyncOptInWalnutUpsellResponseAtom
);

export const getUpsellProductPricesAtom = atom(
  (
    get: Getter
  ): {
    fixedRetailPrice: number;
    fixedPrice: number;
    savingsPercentage: number;
  } => {
    const loadableWalnutProductPrice = get(loadableWalnutProductPriceAtom);
    const interval = get(intervalAtom);
    if (
      loadableWalnutProductPrice.state !== "hasData" ||
      !loadableWalnutProductPrice?.data
    ) {
      return {
        fixedRetailPrice: 0,
        fixedPrice: 0,
        savingsPercentage: 0,
      };
    }
    const { current_price: currentPriceCents, retail_price: retailPriceCents } =
      loadableWalnutProductPrice.data as any;

    const fixedRetailPrice =
      (interval === Interval.ANNUAL
        ? retailPriceCents / 12
        : retailPriceCents) / 100;
    const fixedPrice =
      (interval === Interval.ANNUAL
        ? currentPriceCents / 12
        : currentPriceCents) / 100;
    const savingsPercentage = Math.round(
      100 - (fixedPrice / fixedRetailPrice) * 100
    );

    return {
      fixedRetailPrice,
      fixedPrice,
      savingsPercentage,
    };
  }
);
