import { Modal as BModal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useAtom } from "jotai/index";
import { themeAtom } from "../../lib/theme";
import { calculateComplementary, shadeColor } from "../../utils/color";

export const Modal = (props: {
  title?: string;
  closeButtonCaption?: string;
  acceptButtonCaption?: string;
  children: any;
  headingCaption: string;
  onHide?: () => void;
  onAccept?: () => void;
  modalProperties?: any;
  size: string;
}) => {
  const [theme] = useAtom(themeAtom);

  return (
    <BModal
      {...props.modalProperties}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={
        theme.brand_name != "AKKO"
          ? {
              // @ts-ignore
              "--link-color": theme.color,
              "--border-color": theme.color,
              "--light-background": `${shadeColor(theme.color, 100)}`,
              "--btn-main-caption-color": `${calculateComplementary(
                theme.color,
                true
              )}`,
              "--main-color": theme.color,
            }
          : undefined
      }
    >
      {props.title ? (
        <BModal.Header closeButton={false}>
          <BModal.Title id="contained-modal-title-vcenter">
            {props.headingCaption}
          </BModal.Title>
        </BModal.Header>
      ) : null}
      <BModal.Body>{props.children}</BModal.Body>
      {props.closeButtonCaption || props.acceptButtonCaption ? (
        <BModal.Footer>
          {props.closeButtonCaption ? (
            <Button onClick={props.onHide || (() => null)} variant="secondary">
              {props.closeButtonCaption}
            </Button>
          ) : null}
          {props.acceptButtonCaption ? (
            <Button
              onClick={props.onAccept || (() => null)}
              variant="primary"
              className="filled"
            >
              {props.acceptButtonCaption}
            </Button>
          ) : null}
        </BModal.Footer>
      ) : null}
    </BModal>
  );
};
