export const parseProductApiResponseItem = (item: any) => {
  return {
    id: item.id,
    name: item.name,
    friendlyId: item.friendly_id,
    sku: item.sku,
    termsOfService: item.terms_of_service,
    prices: item.prices.map((p: any) => ({
      currentPrice: p.current_price,
      id: p.id,
      interval: p.interval,
      name: p.name,
      retailPrice: p.retail_price,
      currencyId: p.currency_id,
      annualPrice:
        p.interval === "monthly" ? p.current_price * 12 : p.current_price,
      monthlyPrice:
        p.interval === "monthly" ? p.current_price : p.current_price / 12,
    })),
  };
};
