import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import { DynamicCheckout } from "./pages/DynamicCheckout/DynamicCheckout";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  RouterProvider,
  useLocation,
  useNavigationType,
  matchRoutes
} from "react-router-dom";
import { AKKOTemplate } from "./templates/AKKOTemplate/AKKOTemplate";
import { NotFound } from "./pages/NotFound/NotFound";
import { UpsellToAnnual } from "./pages/UpsellToAnnual/UpsellToAnnual";
import { AKKOLeanTemplate } from "./templates/AKKOLeanTemplate/AKKOLeanTemplate";
import { UpsellToCyberBundle } from "./pages/UpsellToCyberBundle/UpsellToCyberBundle";
import * as Sentry from "@sentry/react";
import {initTracking} from "./services/posthog";
import { CheckoutComplete } from "./pages/CheckoutComplete/CheckoutComplete";

// Start tracking
initTracking()

Sentry.init({
  dsn: "https://fa58cf802b43a335829bed16ca75d529@o537383.ingest.sentry.io/4506735155281920",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "checkout.akko.app",
    "akko-checkout-staging.netlify.app",
    `${process.env.REACT_APP_API}`,
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const router = createBrowserRouter([
  {
    path: ":countryCode/dynamic-checkout/:productUrl",
    element: (
      <AKKOTemplate>
        <DynamicCheckout />
      </AKKOTemplate>
    ),
  },
  {
    path: "post-checkout/mta",
    element: (
      <AKKOLeanTemplate>
        <UpsellToAnnual />
      </AKKOLeanTemplate>
    ),
  },
  {
    path: "post-checkout/cb",
    element: (
      <AKKOLeanTemplate>
        <UpsellToCyberBundle />
      </AKKOLeanTemplate>
    ),
  },
  {
    path: "post-checkout/complete",
    element: (
      <AKKOLeanTemplate>
        <CheckoutComplete />
      </AKKOLeanTemplate>
    ),
  },
  {
    path: "*",
    element: (
      <AKKOTemplate>
        <NotFound />
      </AKKOTemplate>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
