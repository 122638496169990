import styles from "./RegisterCallToAction.module.scss";
import {
  isDataCompleteForRegistrationAtom,
  loadableRequestResponseJsonAtom,
  registrationRequestAtom,
} from "../../lib/registration";
import { useAtom } from "jotai";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { registerConversion } from "../../services/gtm";
import { productAtom, selectedPriceAtom } from "../../lib/product";
import { selectedCurrencyAtom } from "../../lib/country";
import { readDiscountAtom } from "../../lib/discount";
import { setUserAtom } from "../../lib/user";
import useCookie from "../../lib/cookie";
import { identifyUser } from "../../services/posthog";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";

export const RegisterCallToAction = () => {
  const [isDataCompleteForRegistration] = useAtom(
    isDataCompleteForRegistrationAtom
  );
  const { value: irclickid } = useCookie("irclickid");
  const [, registrationRequest] = useAtom(registrationRequestAtom);
  const [loadableRequestResponseJson] = useAtom(
    loadableRequestResponseJsonAtom
  );
  const [selectedCurrency] = useAtom(selectedCurrencyAtom);
  const [readDiscount] = useAtom(readDiscountAtom);
  const [, setUser] = useAtom(setUserAtom);
  const [price] = useAtom(selectedPriceAtom);
  const [product] = useAtom(productAtom);
  const nextPostCheckoutStep = useNextPostCheckoutStep();

  useEffect(() => {
    if (
      loadableRequestResponseJson &&
      loadableRequestResponseJson.state === "hasData" &&
      loadableRequestResponseJson.data
    ) {
      registerConversion(
        loadableRequestResponseJson.data.id,
        price?.currentPrice ? price.currentPrice / 100 : 0,
        selectedCurrency?.code || "USD",
        `${loadableRequestResponseJson.data.id}-${new Date().getTime()}`,
        product?.name || "",
        `${loadableRequestResponseJson.data.id}-${new Date().getTime()}`,
        price?.currentPrice ? price.currentPrice / 100 : 0,
        readDiscount?.code,
        product?.sku || "",
        product?.name || "",
        ""
      );
      setUser({
        id: loadableRequestResponseJson.data.id,
        token: loadableRequestResponseJson.data.access_token,
      });

      identifyUser(loadableRequestResponseJson.data.id);

      nextPostCheckoutStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableRequestResponseJson]);

  return (
    <Button
      variant="primary"
      className={`btn btn-primary fs-6 ${styles.callToActionBtn}`}
      disabled={!isDataCompleteForRegistration}
      onClick={() => registrationRequest(irclickid)}
    >
      Register Now
    </Button>
  );
};
