import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DirectDiscountBox } from "../direct-discount-box/DirectDiscountBox";
import { useAtom } from "jotai";
import { discountAtom } from "../../lib/discount";
import { selectedPriceAtom } from "../../lib/product";
import { Interval } from "../../types/interval";
import { 
  DISCOUNT_CODE_2_YEAR, 
  DISCOUNT_TITLE_2_YEAR, 
  DISCOUNT_SUBTITLE_2_YEAR, 
  DISCOUNT_CONDITIONS_2_YEAR 
} from "../../constants/promotion"

const requestAndVerifyCode = async (
  setDiscountPercent: Dispatch<SetStateAction<number>>
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/v1/discounts/check/${DISCOUNT_CODE_2_YEAR}`,
    {
      method: "GET",
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    setDiscountPercent(data.amount);
  } else {
    setDiscountPercent(0);
  }
};

export const DirectDiscountSection = () => {
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discount, setDiscount] = useAtom(discountAtom);
  const [price] = useAtom(selectedPriceAtom);

  useEffect(() => {
    requestAndVerifyCode(setDiscountPercent);
  }, []);

  return (
    <>
      {price?.interval === Interval.ANNUAL ? (
        <DirectDiscountBox
          appliedDiscount={discount}
          onApplyCode={(code) => setDiscount(code)}
          discountCodePreDefined={DISCOUNT_CODE_2_YEAR}
          title={DISCOUNT_TITLE_2_YEAR.replace("{}", discountPercent.toString())}
          subtitle={DISCOUNT_SUBTITLE_2_YEAR}
          conditions={DISCOUNT_CONDITIONS_2_YEAR}
        />
      ) : null}
    </>
  );
};
