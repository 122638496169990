export enum IntervalToggle {
  // Banners can either show amount savings or percentage savings
  // Banners ONLY support switching from monthly plans to annual plans
  BANNER_AMOUNT = 'ba-a',
  BANNER_PERCENTAGE = 'ba-p',

  // Buttons ONLY display amount savings
  // Buttons can either support two way switching between annual or monthly
  //  or only switching from monthly to annual
  BUTTON_TWO_WAY = 'bu-tw',
  BUTTON_ONE_WAY = 'bu-ow'
}
