import { atom } from "jotai";
import { IntervalToggle } from "../types/plan-interval-switch";
import { Interval } from "../types/interval";
import { intervalAtom } from "./interval";
import {
  savingOnChangePriceAtom,
  selectedPriceAtom,
  counterpartPriceAtom,
} from "./product";
import { getCurrencySymbolAtom } from "../lib/country";
import { formatNumber } from "../utils/plan-interval-switch";

const planIntervalSwitchQueryValueAtom = atom("");

export const setPlanIntervalSwitchQueryValueAtom = atom(
  null,
  (_get, set, paramValue: string) => {
    set(planIntervalSwitchQueryValueAtom, paramValue);
  }
);

export const planIntervalSwitchTypeAtom = atom<IntervalToggle>((get) => {
  const planIntervalSwitchQueryValue = get(planIntervalSwitchQueryValueAtom);
  if (
    planIntervalSwitchQueryValue &&
    Object.values(IntervalToggle).includes(
      planIntervalSwitchQueryValue as IntervalToggle
    )
  ) {
    return planIntervalSwitchQueryValue as IntervalToggle;
  }
  return IntervalToggle.BUTTON_TWO_WAY;
});

export const savingsFormattedReadOnlyAtom = atom((get) => {
  const planIntervalSwitch = get(planIntervalSwitchTypeAtom);
  const interval = get(intervalAtom);
  const savingsAmount = get(savingOnChangePriceAtom);
  const currencySymbol = get(getCurrencySymbolAtom);

  if (savingsAmount) {
    if (planIntervalSwitch === IntervalToggle.BANNER_PERCENTAGE) {
      const selectedPrice = get(selectedPriceAtom);
      const counterpartPrice = get(counterpartPriceAtom);
      if (selectedPrice && counterpartPrice) {
        const denominator =
          interval === Interval.MONTHLY ? selectedPrice : counterpartPrice;
        const savingsPercentage =
          (savingsAmount / denominator.annualPrice) * 100;
        return `${formatNumber(savingsPercentage)}%`;
      }
    } else {
      return `${currencySymbol}${formatNumber(savingsAmount / 100)}`;
    }
  }
  return null;
});

export const displayToggleWhenAnnualAtom = atom((get) => {
  const planIntervalSwitch = get(planIntervalSwitchTypeAtom);
  return planIntervalSwitch === IntervalToggle.BUTTON_TWO_WAY;
});

export const displayToggleBannerAtom = atom((get) => {
  const planIntervalSwitch = get(planIntervalSwitchTypeAtom);
  return (
    planIntervalSwitch === IntervalToggle.BANNER_PERCENTAGE ||
    planIntervalSwitch === IntervalToggle.BANNER_AMOUNT
  );
});
