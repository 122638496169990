import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import styles from "./CustomerBasicInformation.module.scss";
import { CustomerBasicInformation as CustomerBasicInformationInterface } from "../../types/customer";
import { EmailInput } from "../email-input/EmailInput";
import {MapsSearchInputMode} from "../../constants/google-maps";
import {GoogleMapsInput} from "../google-maps-input/GoogleMapsInput";
import {useAtom} from "jotai/index";
import {customerAtom} from "../../lib/customer";

const getPostalCodeFromGMObject = (GMObject: any): string => {
  if (
    GMObject &&
    GMObject.address_components &&
    Array.isArray(GMObject.address_components) &&
    GMObject.address_components[0] &&
    GMObject.address_components[0].short_name
  ) {
    return GMObject.address_components[0].short_name;
  }
  return "";
};

export const CustomerBasicInformation = (props: {
  onInformationChange: (info: CustomerBasicInformationInterface) => void;
  customerBasicInformation: CustomerBasicInformationInterface;
}) => {
  const [customer, setCustomer] = useAtom(customerAtom);

  const [firstName, setFirstName] = useState(
    props.customerBasicInformation.firstName
  );
  const [lastName, setLastName] = useState(
    props.customerBasicInformation.lastName
  );
  const [emailAddress, setEmailAddress] = useState(
    props.customerBasicInformation.emailAddress
  );

  useEffect(() => {
    props.onInformationChange({ firstName, lastName, emailAddress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, firstName, lastName]);

  return (
    <Form.Group>
      <div
        className={`d-flex justify-content-between mb-3 ${styles.mFlexColumn}`}
      >
        <Form.Control
          className={`Control ${styles.cardName} ${styles.mFullWidth}`}
          autoComplete="cc-given-name"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <Form.Control
          className={`Control ${styles.cardName} ${styles.mFullWidth} ${styles.mt1}`}
          autoComplete="cc-family-name"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      {/*<Form.Control*/}
      {/*  className="Control"*/}
      {/*  placeholder="Email Address"*/}
      {/*  value={emailAddress}*/}
      {/*  onChange={(e) => setEmailAddress(e.target.value)}*/}
      {/*/>*/}
      <div
        className={`d-flex justify-content-between mb-3 ${styles.mFlexColumn}`}
      >
        <div className={`${styles.cardName} ${styles.mFullWidth}`}>
          <EmailInput
            className={'Control'}
            onEmailChange={(email) => setEmailAddress(email)}
          />
        </div>
        <GoogleMapsInput
          name={"postal-code"}
          id={"postal-code"}
          placeHolder={"Postal Code"}
          cssClasses={`Control ${styles.cardName} ${styles.mFullWidth} ${styles.mt1}`}
          value={customer.postalCode}
          inputType={MapsSearchInputMode.REGION}
          onAddressChange={(GMObject) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode: getPostalCodeFromGMObject(GMObject),
            }))
          }
          onPlainValueChange={(postalCode) =>
            setCustomer((prev) => ({
              ...prev,
              postalCode,
            }))
          }
        />
      </div>
    </Form.Group>
  );
};
