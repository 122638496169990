import { atom } from "jotai";
import { AvailablePaymentMethods } from "../types/available-payment-methods";
import { PaymentMethod } from "../types/payment-information";
import { paymentInformationAtom } from "./payment-information";

const availablePaymentMethodQueryValueAtom = atom<string>("");

export const setAvailablePaymentMethodQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(availablePaymentMethodQueryValueAtom, queryValue);
    if (queryValue === AvailablePaymentMethods.CREDIT_CARD) {
      set(paymentInformationAtom, (p) => ({
        ...p,
        paymentMethod: PaymentMethod.CARD,
      }));
    }
    if (queryValue === AvailablePaymentMethods.ACH) {
      set(paymentInformationAtom, (p) => ({
        ...p,
        paymentMethod: PaymentMethod.ACH,
      }));
    }
  },
);

export const availablePaymentMethodAtom = atom<AvailablePaymentMethods>(
  (get) => {
    const availablePaymentMethodQueryValue = get(
      availablePaymentMethodQueryValueAtom,
    );
    if (availablePaymentMethodQueryValue) {
      return availablePaymentMethodQueryValue as AvailablePaymentMethods;
    }
    return AvailablePaymentMethods.ALL;
  },
);
