import { DiscountCode } from "../discount-code/DiscountCode";
import { DiscountInfoBox } from "../discount-info-box/DiscountInfoBox";
import { useAtom } from "jotai";
import { discountAtom } from "../../lib/discount";
import { selectedPriceAtom } from "../../lib/product";
import styles from "./PromotionSection.module.scss";

export const PromotionSection = () => {
  const [discount, setDiscount] = useAtom(discountAtom);
  const [price] = useAtom(selectedPriceAtom);

  return (
    <>
      <h2 className="fs-4 mb-0">Promotions</h2>
      <div className={`fs-7 mb-2 ${styles.caption}`}>
        Enter a promotion code you have, or select from one of our current promotions below! Limit 1 per transaction/promotions are not stackable.
      </div>
      <DiscountCode
        appliedDiscount={discount}
        onApplyCode={(code) => setDiscount(code)}
      />
      {discount != null && price ? (
        <DiscountInfoBox
          applied={true}
          discount={discount}
          price={price}
          className="mt-4"
        />
      ) : null}
    </>
  );
};
