import { ReactComponent as PhoneLock } from "../../assets/phone-lock.svg";
import { ReactComponent as PhoneAKKO } from "../../assets/phone-akko.svg";
import { ReactComponent as CheckMark } from "../../assets/checkmark.svg";
import { ReactComponent as CheckmarkButton } from "../../assets/checkmark-button.svg";
import { ReactComponent as Chevron } from "../../assets/chevron-icon.svg";
import styles from "./UpsellToCyberBundle.module.scss";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { LoadingModal } from "../../components/loading-modal/LoadingModal";
import {
  getUpsellProductPricesAtom,
  loadableOptInWalnutUpsellResponseAtom,
  optInWalnutUpsellAtom,
} from "../../lib/product-upsell";
import { UpsellToCyberbundleConfirmationModal } from "../../components/upsell-to-cyberbundle-confirmation-modal/UpsellToCyberbundleConfirmationModal";
import { getCurrencySymbolAtom } from "../../lib/country";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";

const opInCyberBundle = (optInWalnutUpsell: () => void) => {
  optInWalnutUpsell();
};

export const UpsellToCyberBundle = () => {
  const nextPostCheckoutStep = useNextPostCheckoutStep();
  const [showBenefits, setShowBenefits] = useState(true);
  const [, optInWalnutUpsell] = useAtom(optInWalnutUpsellAtom);
  const [loadableOptInWalnutUpsellResponse] = useAtom(
    loadableOptInWalnutUpsellResponseAtom
  );
  const [getUpsellProductPrices] = useAtom(getUpsellProductPricesAtom);
  const [showOptInConfirmation, setShowOptInConfirmation] = useState(false);
  const [getCurrencySymbol] = useAtom(getCurrencySymbolAtom);

  useEffect(() => {
    if (
      loadableOptInWalnutUpsellResponse.state === "hasData" &&
      loadableOptInWalnutUpsellResponse.data !== null
    ) {
      nextPostCheckoutStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableOptInWalnutUpsellResponse]);

  return (
    <>
      <div className={styles.outsideContainer}>
        <main className={`${styles.container}`}>
          <h5 className="mt-3">Special Offer for AKKO Members!</h5>
          <p className="fs-7">
            You've taken the first step to protect your devices from physical
            harm, but don't forget to add this great offer to protect your
            digital identity and well-being.
          </p>
          <div className={styles.promotionDetailsContainer}>
            <p className="fs-7">
              <b>Bundled Plan</b> CyberScout + Dashlane Premium
            </p>
            <CheckmarkButton className={styles.checkmarkButton} />
            <div className={`${styles.planBenefitsContainer} d-flex`}>
              <div className={styles.planBenefitContainer}>
                <div
                  className={`${styles.planBenefitTittleContainer} d-flex ${
                    showBenefits ? styles.separator : ""
                  }`}
                >
                  <PhoneLock className={styles.phoneIllustration} />
                  <div>
                    <h6>CyberScout</h6>
                    <p className="fs-7">
                      $1M identify theft protection <br />+ ransomware
                      protection
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.planBenefitListContainer} ${
                    showBenefits ? "" : styles.collapsed
                  }`}
                >
                  <ul className={`${styles.benefitList} fs-7`}>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        $1M Identity Reimbursement Coverage
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        $25K Ransomware Coverage
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        $25K Social Engineering Coverage
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        24/7 Cyber Support Line
                      </span>
                    </li>
                    <li>
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Credit Monitoring Services
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.planBenefitContainer}>
                <div
                  className={`${styles.planBenefitTittleContainer} d-flex ${
                    showBenefits ? styles.separator : ""
                  }`}
                >
                  <PhoneAKKO className={styles.phoneIllustration} />
                  <div>
                    <h6>Dashlane Premium</h6>
                    <p className="fs-7">
                      Premium password manager + VPN service & dark web
                      monitoring
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.planBenefitListContainer} ${
                    showBenefits ? "" : styles.collapsed
                  }`}
                >
                  <ul className={`${styles.benefitList} fs-7`}>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Unlimited Password Storage
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Dark Web Monitoring
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Personalized Security Alerts
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Encrypted File Storage (1 GB)
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Password Health Checker
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Password Generator
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Form & Payment Autofill
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Automatic Password Changer
                      </span>
                    </li>
                    <li className="mb-2">
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Unlimited Device Access
                      </span>
                    </li>
                    <li>
                      <CheckMark />
                      <span className={styles.itemCaption}>
                        {" "}
                        Secure Password Sharing
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex pt-4 justify-content-center">
              <Button
                className={`text-decoration-none fs-7 fw-bold ${styles.noShadow}`}
                variant="link"
                style={{ color: "#6c6c6c" }}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setShowBenefits(!showBenefits);
                }}
              >
                {`${showBenefits ? "Hide" : "Show"}`} All Benefits{"  "}
                <Chevron
                  className={`${styles.chevronIcon} ${
                    showBenefits ? "" : styles.collapsed
                  }`}
                />
              </Button>
            </div>
          </div>
          <div className={`${styles.callToActionContainer}`}>
            <div className={styles.promotionCostSection}>
              <div className={styles.amountsContainer}>
                <span className={`${styles.percentageDiscount} cera`}>
                  {getUpsellProductPrices.savingsPercentage}% off!
                </span>
                <span className={`${styles.noDiscountedPrice} cera`}>
                  {getCurrencySymbol}
                  {getUpsellProductPrices.fixedRetailPrice.toFixed(0)}
                </span>
                <span className={`${styles.finalPrice} cera`}>
                  {getCurrencySymbol}
                  {getUpsellProductPrices.fixedPrice.toFixed(2)}
                  <span className={styles.interval}>/ Month</span>
                </span>
              </div>
              <span className={`${styles.billingPeriodCaption} fs-8`}>
                Billed on the same interval as your primary subscription
              </span>
            </div>
            <div className={`d-flex gap-3 ${styles.buttonContainer}`}>
              <Button
                variant="secondary"
                className={`${styles.secondary} caption fs-7 ${styles.showLargeScreen}`}
                onClick={nextPostCheckoutStep}
              >
                Skip for Now
              </Button>
              <Button
                variant="primary"
                className={`${styles.secondary} ${styles.showResponsive}`}
                onClick={nextPostCheckoutStep}
              >
                Skip for Now
              </Button>
              <Button
                variant="primary"
                className={`${styles.callToActionBtn} filled fs-7`}
                onClick={() => setShowOptInConfirmation(true)}
              >
                Add to My Plan
              </Button>
            </div>
          </div>
        </main>
      </div>
      <LoadingModal />
      <UpsellToCyberbundleConfirmationModal
        show={showOptInConfirmation}
        onSkip={nextPostCheckoutStep}
        onAccept={() => opInCyberBundle(optInWalnutUpsell)}
      />
    </>
  );
};
