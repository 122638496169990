export enum PostCheckoutSteps {
  MONTHLY_TO_ANNUAL = "MONTHLY_TO_ANNUAL",
  CYBER_BUNDLE = "CYBER_BUNDLE",
  WELCOME_SCREEN = "WELCOME_SCREEN",
  COMPLETE_SCREEN = "COMPLETE_SCREEN",
}

export enum MonthlyToAnnualVariation {
  PLAIN_AMOUNT = "PLAIN_AMOUNT",
  PERCENTAGE_AMOUNT = "PERCENTAGE_AMOUNT",
}

export const DEFAULT_POST_CHECKOUT_FLOW = [
  PostCheckoutSteps.CYBER_BUNDLE,
  PostCheckoutSteps.MONTHLY_TO_ANNUAL,
  PostCheckoutSteps.COMPLETE_SCREEN,
  PostCheckoutSteps.WELCOME_SCREEN,
];