import { atom } from "jotai";

export const partnerPropertyUrlQueryValueAtom = atom<string>("");

export const setPartnerPropertyUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(partnerPropertyUrlQueryValueAtom, queryValue);
  }
);

export const salesRepUrlQueryValueAtom = atom<string>("");

export const setSalesRepUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(salesRepUrlQueryValueAtom, queryValue);
  }
);

export const partnerPropertyFriendlyUrlQueryValueAtom = atom<string>("");

export const setPartnerPropertyFriendlyUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(partnerPropertyFriendlyUrlQueryValueAtom, queryValue);
  }
);

export const salesRepFriendlyUrlQueryValueAtom = atom<string>("");

export const setSalesRepFriendlyUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(salesRepFriendlyUrlQueryValueAtom, queryValue);
  }
);