import { atom } from "jotai";
import { Customer } from "../types/customer";

export const customerAtom = atom<Customer>({
  phone: "",
  isPhoneValid: false,
  primary: true,
  firstName: "",
  lastName: "",
  emailAddress: "",
  address: "",
  postalCode: "",
});

export const customerEmailAtom = atom(
  (get) => get(customerAtom).emailAddress,
  (get, set, email: string) => {
    const customer = get(customerAtom);
    set(customerAtom, {
      ...customer,
      emailAddress: email,
    });
  }
);
