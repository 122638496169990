export enum PaymentMethod {
  ACH = "ACH",
  CARD = "CARD",
}

export interface PaymentInformation {
  stripeToken: string;
  paymentMethod: PaymentMethod;
  plaidClientId: string;
  bankAccountId: string;
  plaidPublicToken: string;
}
