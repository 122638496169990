import { atom } from "jotai";
import { loadable } from "jotai/utils";

const asyncRequestToUpdateCoverageResponseAtom = atom<any | null>(null);

export const loadableRequestResponseToUpdateCoverageAtom = loadable(
  asyncRequestToUpdateCoverageResponseAtom
);

export const updateToPrice = atom(
  null,
  (get, set, targetCoverageId: string | undefined) => {
    // const user = get(userAtom);
    // if (!user) {
    //   return set(
    //     asyncRequestToUpdateCoverageResponseAtom,
    //     new Promise(() => null)
    //   );
    // }
    // const res = fetch(
    //   `${process.env.REACT_APP_API}/users/${user.id}/coverage/`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${user.token}`,
    //     },
    //     body: JSON.stringify({ coverage_id: targetCoverageId }),
    //   }
    // );
    // set(asyncRequestToUpdateCoverageResponseAtom, res);
  }
);

export const updateCoverageRequestResponseAtom = atom<any | Response>((get) => {
  const loadableRequestResponseToUpdateCoverage = get(
    loadableRequestResponseToUpdateCoverageAtom
  );

  if (!loadableRequestResponseToUpdateCoverage) return null;
  if (loadableRequestResponseToUpdateCoverage.state === "loading") return null;
  if (loadableRequestResponseToUpdateCoverage.state === "hasError")
    return loadableRequestResponseToUpdateCoverage.error;
  if (loadableRequestResponseToUpdateCoverage.state === "hasData")
    return loadableRequestResponseToUpdateCoverage.data;

  return null;
});
