import { atom } from "jotai";
import { userAtom } from "./user";
import { loadable } from "jotai/utils";

export const getUserContractAtom = atom((get): any | null => {
  const user = get(userAtom);
  if (!user) return null;

  return fetch(`${process.env.REACT_APP_API}/users/${user.id}/contracts/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ force_new: false }),
  }).then((r) => r.json());
});

export const loadableGetUserContractAtom = loadable(getUserContractAtom);
