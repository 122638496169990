export enum CountryCurrencies {
  US = "USD",
  CA = "CAD",
}

export interface Currency {
  code: string;
  id: string;
  name: string;
  symbol: string;
}
