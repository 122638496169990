import { atom } from "jotai";

export const priceGroupUrlQueryValueAtom = atom<string>("");

export const setPriceGroupUrlQueryValueAtom = atom(
  null,
  (_get, set, queryValue: string) => {
    set(priceGroupUrlQueryValueAtom, queryValue);
  }
);
