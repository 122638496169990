import { Modal } from "../modal/Modal";

export const UpsellToCyberbundleConfirmationModal = (props: {
  show: boolean;
  onSkip: () => void;
  onAccept: () => void;
}) => {
  return (
    <Modal
      title="Confirm Add-On Purchase"
      headingCaption="Confirm Add-On Purchase"
      modalProperties={{
        show: props.show,
      }}
      closeButtonCaption="Skip for now"
      acceptButtonCaption="Add to my plan"
      size="md"
      onHide={props.onSkip}
      onAccept={props.onAccept}
    >
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "1em", paddingLeft: "1em" }}
      >
        <p>
          Upon confirming, the bundled add-on plan will be instantly added to
          your account followed by instructions on how to set it up. Are you
          sure you want to continue?
        </p>
      </div>
    </Modal>
  );
};
