import Button from "react-bootstrap/Button";
import { usePlaidLink } from "react-plaid-link";
import { useCallback } from "react";
import { achAccountConnectionPayload } from "../AchConnection";

interface LinkProps {
  linkToken: string | null;
  onUpdateAchPaymentData: (payload: achAccountConnectionPayload) => void;
  buttonClass?: string;
  caption?: string;
}

export const AchConnectionButton = (props: LinkProps) => {
  const onSuccess = useCallback(
    (publicToken: string, metadata: any) => {
      props.onUpdateAchPaymentData({
        public_token: publicToken,
        account_id: metadata.account_id,
        accountMetadata: metadata,
      });
    },
    [props]
  );

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <Button
      variant="primary"
      onClick={() => open()}
      disabled={!ready}
      className={`${props.buttonClass ? props.buttonClass : "btn-action"} fs-8`}
    >
      {props.caption ? props.caption : "Connect Your Bank"}
    </Button>
  );
};
