import { PaymentMethodSwitch } from "../payment-method-switch/PaymentMethodSwitch";
import { CheckoutCard } from "../checkout-card/CheckoutCard";
import { PaymentMethod } from "../../constants/payment";
import { AchConnection } from "../ach-connection/AchConnection";
import { useAtom } from "jotai";
import { customerAtom } from "../../lib/customer";
import { paymentInformationAtom } from "../../lib/payment-information";
import { loadableRequestResponseAtom } from "../../lib/registration";
import { availablePaymentMethodAtom } from "../../lib/available-payment-methods";
import { AvailablePaymentMethods } from "../../types/available-payment-methods";

export const PaymentInformation = () => {
  const [customer, setCustomer] = useAtom(customerAtom);
  const [paymentInformation, setPaymentInformation] = useAtom(
    paymentInformationAtom
  );
  const [loadableRequestResponse] = useAtom(loadableRequestResponseAtom);
  const [availablePaymentMethod] = useAtom(availablePaymentMethodAtom);

  return (
    <>
      <h2 className="fs-4 mb-3">Payment Information</h2>
      {availablePaymentMethod === AvailablePaymentMethods.ALL && (
        <PaymentMethodSwitch
          method={paymentInformation.paymentMethod}
          onMethodUpdate={(method) =>
            setPaymentInformation((prev) => ({
              ...prev,
              paymentMethod: method,
              stripeToken: "",
            }))
          }
        />
      )}
      <div className="mt-4">
        {paymentInformation.paymentMethod === PaymentMethod.CARD ? (
          <CheckoutCard
            registrationRequestState={loadableRequestResponse.state}
            customerBasicInformation={{
              firstName: customer.firstName,
              lastName: customer.lastName,
              emailAddress: customer.emailAddress,
            }}
            onStripeTokenChange={(token) =>
              setPaymentInformation((prev) => ({
                ...prev,
                stripeToken: token,
              }))
            }
            onCustomerBasicInformationChange={(info) => {
              setCustomer((prev) => ({
                ...prev,
                firstName: info.firstName,
                lastName: info.lastName,
                emailAddress: info.emailAddress,
              }));
            }}
          />
        ) : (
          <AchConnection />
        )}
      </div>
    </>
  );
};
