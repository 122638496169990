const pushToDatalayer = function (payload: any): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.dataLayer) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.dataLayer.push(payload);
};

const fbInstance = function (...args: any[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.fbq) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.fbq(...args);
};

const impactAffiliateInstance = function (...args: any[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.ire) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.ire(...args);
};

export const registerConversion = (
  userId: number,
  conversionValue: number,
  conversionCurrency: string,
  conversionTransactionId: string,
  plan: string,
  transactionId: string,
  transactionTotal: number,
  coupon: string | undefined,
  itemId: string,
  itemName: string,
  itemCovered: string
): void => {
  pushToDatalayer({
    event: "purchase-trigger",
    conversionValue: conversionValue,
    conversionCurrency: conversionCurrency,
    conversionTransactionId: transactionId,
    plan: plan,
    user: userId,
    transactionId: transactionId,
    transactionTotal: conversionValue,
    coupon: coupon,
    transactionProducts: [
      {
        item_id: itemId,
        item_name: itemName,
        price: conversionValue,
        quantity: 1,
      },
    ],
  });

  fbInstance("track", "Purchase", {
    value: conversionValue,
    currency: conversionCurrency,
    content_name: itemName,
  });

  impactAffiliateInstance("identify", {
    customerId: userId,
    customerEmail: "",
  });
  impactAffiliateInstance(
    "trackConversion",
    25187,
    {
      orderId: transactionId,
      customerId: userId.toString(),
      customerEmail: "",
      currencyCode: conversionCurrency,
      customerStatus: "New",
      note: "",
      orderPromoCode: coupon,
      items: [
        {
          subTotal: conversionValue,
          category: itemCovered,
          sku: itemId,
          quantity: 1,
          name: itemName,
        },
      ],
    },
    {
      verifySiteDefinitionMatch: true,
    }
  );
};
