import { Modal } from "../modal/Modal";
import { loadableRequestResponseToUpdateCoverageAtom } from "../../lib/update-coverage";
import { useAtom } from "jotai";
import { useNextPostCheckoutStep } from "../../utils/useNextPostCheckoutStep";

export const UpsellMonthlyToAnnualError = () => {
  const [loadableRequestResponseToUpdateCoverage] = useAtom(
    loadableRequestResponseToUpdateCoverageAtom
  );
  const nextPostCheckoutStep = useNextPostCheckoutStep();

  return (
    <Modal
      headingCaption="An error ocurrered"
      modalProperties={{
        show:
          loadableRequestResponseToUpdateCoverage.state === "hasError" ||
          (loadableRequestResponseToUpdateCoverage.state === "hasData" &&
            loadableRequestResponseToUpdateCoverage.data &&
            !loadableRequestResponseToUpdateCoverage.data.ok),
      }}
      closeButtonCaption="Accept"
      size="md"
      onHide={nextPostCheckoutStep}
    >
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "1em", paddingLeft: "1em" }}
      >
        <p>An error occurred while upgrading your coverage.</p>
      </div>
    </Modal>
  );
};
