import { Form } from "react-bootstrap";
import { MapsSearchInputMode } from "../../constants/google-maps";
import { useEffect, useRef, useState } from "react";

export const GoogleMapsInput = (props: {
  name: string;
  id: string;
  placeHolder: string;
  cssClasses: string;
  value: string;
  inputType: MapsSearchInputMode;
  onAddressChange: (arg0: any) => void;
  onPlainValueChange: (arg0: any) => void;
}) => {
  const [searchTypes, setSearchTypes] = useState<string[]>([]);
  const inputRef = useRef(null);
  const searchBox = useRef<any>(null);

  useEffect(() => {
    if (props.inputType === MapsSearchInputMode.PLACE) {
      setSearchTypes(["establishment"]);
    } else if (props.inputType === MapsSearchInputMode.ADDRESS) {
      setSearchTypes(["address"]);
    } else if (props.inputType === MapsSearchInputMode.REGION) {
      setSearchTypes(["(regions)"]);
    }
  }, [props.inputType]);

  useEffect(() => {
    // @ts-ignore
    if (inputRef.current == null || !window.googleMapsInitialed) {
      console.warn("Maps not initialized");
      return;
    }

    // @ts-ignore
    searchBox.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: searchTypes,
        componentRestrictions: {
          country: ["us", "ca", "pr", "vi", "gu"],
        },
      }
    );

    if (searchBox.current == null) {
      return;
    }

    searchBox.current?.addListener("place_changed", () => {
      const place = searchBox.current.getPlace();
      if (place) {
        props.onAddressChange(place);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTypes, inputRef]);

  return (
    <Form.Control
      placeholder={props.placeHolder}
      className={props.cssClasses}
      id={props.id}
      name={props.name}
      ref={inputRef}
      value={props.value}
      onChange={(e) => props.onPlainValueChange(e.target.value)}
    />
  );
};
